<template>
  <div>
    <Container>
      <Card padding="large">
        <Margins>
          <h5 class="h5 text-normal">
            {{ $t('RENEWAL.HEADER') }}
          </h5>
          <p class="text-small">{{ $t('RENEWAL.DESCRIPTION') }}</p>

          <div
            v-for="(items, key, index) in products"
            :key="`order${index}`"
            :name="`order${index}`"
          >
            <h6 class="h6 text-normal">
              {{ key }}
            </h6>
            <div
              v-for="product in items"
              :key="product.productName"
              class="mt-2"
            >
              <Check
                v-model="formData.readyToRenew"
                :label="product.productName"
                :name="`ready-to-renew-${index}`"
                @change="onProductSelected(product)"
              />
            </div>
          </div>

          <form @submit.prevent="onConfirmRenewals" id="renewalForm">
            <ul
              class="margins__double"
              v-if="formData.renewableSubscriptionReference"
            >
              <li class="m-4">
                <Check
                  v-model="formData.renewalTerms"
                  :label="$t('RENEWAL.RENEWAL_TERMS')"
                  name="renewalTerms"
                  :error="errors.first('renewalTerms')"
                  v-validate="'required: true'"
                />
              </li>
              <li class="m-4">
                <Check
                  v-model="formData.fairownRenewalTerms"
                  :label="$t('RENEWAL.FAIROWN_CONSENT')"
                  name="fairownRenewalTerms"
                  :error="errors.first('fairownRenewalTerms')"
                  v-validate="'required: true'"
                />
              </li>
            </ul>
            <b-row align-h="center" class="margins__double">
              <b-col cols="auto">
                <Button
                  tag="button"
                  type="submit"
                  form="renewalForm"
                  variant="inverse-primary"
                >
                  {{ $t('CONTINUE') }}
                </Button>
              </b-col>
            </b-row>
          </form>
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Button, Card, Container, Margins, Check } from '@/components';
import { constants } from '@/mixins';
import { SET_RENEWAL_ORDER, HANDLE_PAYMENT_RESPONSE } from '@/types';

export default {
  name: 'Renewal',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Check,
  },
  data() {
    return {
      isReadyToRenew: false,
      products: [],
      formData: {
        readyToRenew: false,
      },
    };
  },
  computed: {
    ...mapState(['renewableProducts', 'decisionStatus']),
  },

  mounted() {
    this.products = { ...this.renewableProducts };
  },

  methods: {
    ...mapActions([SET_RENEWAL_ORDER, HANDLE_PAYMENT_RESPONSE]),

    onProductSelected(product) {
      if (this.formData.readyToRenew) {
        // Filter products based on selected product
        const filteredData = {};
        for (const key in this.products) {
          const filteredItems = this.products[key].filter(
            (item) => item.productName === product.productName,
          );
          if (filteredItems.length > 0) {
            filteredData[key] = filteredItems;
          }
        }
        // Update products and form data
        this.products = { ...filteredData };
        this.formData = {
          ...this.formData,
          renewableSubscriptionReference: product.subscriptionReferenceNumber,
        };
      } else {
        // Restore original products and reset subscription reference
        this.products = { ...this.renewableProducts };
        this.formData.renewableSubscriptionReference = null;
      }
    },

    async onConfirmRenewals() {
      if (this.formData.readyToRenew) {
        await this.$validator.validateAll();
        await this.SET_RENEWAL_ORDER(this.formData);
        this.$validator.reset();
      } else {
        await this.HANDLE_PAYMENT_RESPONSE(this.decisionStatus);
      }
    },
  },
};
</script>
